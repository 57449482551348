import React from 'react';
import './Hero.scss';

const HeaderImage = ({ background }) => (
  <section
    className={'hero is-primary is-medium has-background-image'}
    style={{
      backgroundImage: `url("${background}")`,
    }}
  >
    {background && <div clasName="hero-overlay" />}
    <div className="hero-body" />
  </section>
);

export default HeaderImage;
