import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import HeaderImage from '../components/HeaderImage';

const PageTemplate = ({ data, pageContext }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const { title } = frontmatter;
  const headerImage =
    pageContext.headerImage &&
    pageContext.headerImage.childImageSharp.fluid.srcWebp;

  return (
    <Layout title={title}>
      {headerImage && <HeaderImage background={headerImage} />}
      <div className="container pt-5 px-3 pb-3">
        <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`;
